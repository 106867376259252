/**
 * Created by css on 2019/3/15.
 */
$(function () {
    var flag = 0;
    $('#nav-icon3').click(function () {
        var $this = $(this);
        var $header = $('#header');
        if (flag == 1) {
            $this.removeClass('active');
            $header.removeClass('open');
            flag = 0;
        } else {
            $this.addClass('active');
            $header.addClass('open');
            flag = 1;
        }
    });
    //insert header nav
    $('.nav-menu').clone().insertAfter('#nav-icon3');
});